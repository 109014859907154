import React, { useEffect, useState } from "react";
import AuthTextLeft from "../components/AuthTextLeft";
import { Form } from "react-bootstrap";
// import DefaultInput from "../../components/input/DefaultInput";
import DefaultButton from "../../components/button/DefaultButton";
import { isValidEmail, isValidPhoneNumber } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  clearGoBackType,
  clearStatusAndOTPResponse,
} from "../../../redux-store/slices/auth";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  JOIN_MY_GROUP,
  REGISTER_REQUEST,
} from "../../../redux-store/sagas/saga-actions";
import { toast } from "react-toastify";
import Spinner from "../../components/loader/Spinner";
import { GET_GROUP_DETAILS } from "../../../redux-store/sagas/saga-actions";
import "../css/joinGroupDetails.css";
import GroupDetails from "../components/GroupDetails";
import MobileNoInput from "../components/MobileNoInput";
import useJoinGroupInfo from "hooks/useJoinGroupInfo";
import { Link } from "react-router-dom";
import { isUserAllowed } from "utils/helpers/env";
import useDetectUserAgent from "hooks/useDetectUserAgent";
import PhotographerErrorModal from "views/components/modals/PhotographerError";
import { lsProxy } from "utils/helpers/localstorage";
import ENV from "utils/helpers/env";

const Login = () => {
  const [countryCode, setCountryCode] = useState("+44");
  const [errorModal, setErrorModal] = useState(false);
  const [deviceType, setDeviceType] = useState({ type: "desktop" });
  const { state } = useLocation();
  const { email } = state || {};
  useDetectUserAgent(setDeviceType);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = React.useState();
  const {
    user,
    registerResponse: { status, message } = {},
    loading,
  } = useSelector((state) => state.auth);
  const [val, setVal] = useState(email ?? "");
  const [params] = useSearchParams();
  const groupDetails = useSelector((state) => state.groups.group?.data);
  const { country } = useSelector((state) => state.globals);

  const { loading: joinGroupLoading, joinNewGroupSocketInit } =
    useJoinGroupInfo();

  const [groupRegister, setGroupRegister] = useState(false);

  const uCode = params.get("uCode");
  const adminToken = params.get("adminToken");

  /**
   * - true: allow only phone number
   * - false/*: allow only email
   * - null: allow both
   */
  const allowOnlyPhone = params.has("allowPhone")
    ? params.get("allowPhone") == "true"
    : null;

  const isAuthenticated = lsProxy.getItem("token");

  useEffect(() => {
    if (country) {
      setCountryCode(country?.dial_code);
    }
  }, [country]);

  useEffect(() => {
    dispatch(clearGoBackType());
    if (!uCode) {
      if (isAuthenticated) {
        navigate("/groups", {
          replace: true,
        });
      } else {
        lsProxy.clear();
      }
    }
  }, []);

  useEffect(() => {
    if (uCode) {
      if (!groupDetails) {
        dispatch({
          type: GET_GROUP_DETAILS,
          groupCode: uCode,
        });
      }
      if (isAuthenticated) {
        joinNewGroupSocketInit();
        dispatch({
          type: JOIN_MY_GROUP,
          groupCode: uCode,
          adminToken,
        });
      } else {
        setGroupRegister(true);
      }
    } else {
      setGroupRegister(false);
    }
  }, [uCode, groupDetails]);

  useEffect(() => {
    if (status === 200 || status === 201) {
      dispatch(clearStatusAndOTPResponse());
      if (isUserAllowed(user)) {
        if (message) toast.success(message);

        if (uCode && adminToken) {
          navigate(`/auth/otp?uCode=${uCode}&adminToken=${adminToken}`);
        } else if (uCode) {
          navigate(`/auth/otp?uCode=${uCode}`);
        } else {
          navigate(`/auth/otp`);
        }
      } else {
        setErrorModal(true);
      }
    } else if (status === 422) {
      setCountryCode(country?.dial_code || "+1");
      setVal("");
      dispatch(clearStatusAndOTPResponse());
    }
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const loginCredentialInput = e.target.loginCredential.value.trim();
    let credentials = {};

    if (loginCredentialInput === "") {
      setError(
        allowOnlyPhone === null
          ? "Please enter a valid email or phone number"
          : allowOnlyPhone
          ? "Please enter a valid phone number"
          : "Please enter a valid email"
      );
      return;
    }

    if (
      isValidPhoneNumber(loginCredentialInput, countryCode) &&
      (allowOnlyPhone || allowOnlyPhone === null)
    ) {
      credentials.phoneNumber = loginCredentialInput;
      credentials.loginType = "PHONE";
      credentials.countryCode = countryCode; // need to set the actual country code.
    } else if (
      isValidEmail(loginCredentialInput) &&
      (!allowOnlyPhone || allowOnlyPhone === null)
    ) {
      credentials.email = loginCredentialInput;
      credentials.loginType = "EMAIL";
    } else if (
      loginCredentialInput.includes("#") &&
      loginCredentialInput.split("#").length == 2
    ) {
      const loginList = loginCredentialInput.split("#");
      if (
        isValidPhoneNumber(loginList[0]) &&
        isValidPhoneNumber(loginList[1])
      ) {
        credentials.phoneNumber = loginCredentialInput;
        credentials.loginType = "PHONE";
        credentials.countryCode = countryCode;
      } else if (isValidEmail(loginList[0]) && isValidEmail(loginList[1])) {
        credentials.email = loginCredentialInput;
        credentials.loginType = "EMAIL";
      }
    }

    if (Object.keys(credentials).length > 0) {
      dispatch({
        type: REGISTER_REQUEST,
        payload: {
          ...credentials,
          sendOtp: false,
        },
      });
    } else {
      setError(
        allowOnlyPhone === null
          ? "Please enter a valid email or phone number"
          : allowOnlyPhone
          ? "Please enter a valid phone number"
          : "Please enter a valid email"
      );
    }
  };

  return (
    <>
      {!groupRegister ? (
        <div className="d-flex align-items-center">
          <Spinner loading={loading || joinGroupLoading} />
          <AuthTextLeft class="col7 " />
          <div className="col5">
            <AuthFormContainer
              text={ENV.WL_WELCOME_MESSAGE}
              handleSubmit={handleSubmit}
              val={val}
              setVal={setVal}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              error={error}
              groupDetails={groupDetails}
              allowPhone={allowOnlyPhone}
            />
          </div>
        </div>
      ) : (
        <div className="join-group-flex">
          <Spinner loading={loading || joinGroupLoading} />
          <GroupDetails groupDetails={groupDetails} />
          <div className="col5-join">
            <AuthFormContainer
              text="Join a group"
              handleSubmit={handleSubmit}
              val={val}
              setVal={setVal}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              error={error}
              groupDetails={groupDetails}
              // setError={setError}
              header={window.innerWidth > 768}
              allowPhone={allowOnlyPhone}
            />
          </div>
        </div>
      )}
      <PhotographerErrorModal
        show={errorModal}
        hide={() => setErrorModal(false)}
        deviceType={deviceType}
        type="login"
      />
    </>
  );
};

export default Login;

const AuthFormContainer = ({
  text,
  handleSubmit,
  val,
  setVal,
  countryCode,
  setCountryCode,
  error,
  header = true,
  groupDetails,
  allowPhone,
}) => {
  const [params] = useSearchParams();
  const adminToken = params.get("adminToken");

  return (
    <div className="authFormContainer">
      {header && <div className="authHeader"></div>}
      <div className="authFormCard">
        <div>
          <h2>{text}</h2>
          <span>
            Enter your&nbsp;
            {allowPhone === null
              ? "Email ID or Phone Number"
              : allowPhone
              ? "Phone Number"
              : "Email ID"}
            &nbsp;to continue
          </span>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="m-0 mt-md-3">
            <MobileNoInput
              name={"loginCredential"}
              placeholder={`${
                allowPhone === null
                  ? "Email/Phone Number"
                  : allowPhone
                  ? "Phone Number"
                  : "Email ID"
              }`}
              val={val}
              setVal={setVal}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              error={error}
              onChange={(e) => setVal(e.target.value)}
              allowOnlyPhone={allowPhone === null || allowPhone}
            />
          </div>
          <div className="mt-3"></div>
          <DefaultButton title="Continue" type="submit" />

          {groupDetails?.anonymousAccess ? (
            <Link
              to={`/anonymous/anonymous-selfies?groupId=${
                groupDetails?.groupId
              }${adminToken ? `&adminToken=${adminToken}` : ""}`}
            >
              <p className="mt-3 fw-semibold text-decoration-underline">
                Skip Login
              </p>
            </Link>
          ) : (
            ""
          )}
        </Form>
      </div>
    </div>
  );
};
